import { PortalNav } from 'bl-common/src/units/Navigation/PortalNav'

import { Metadata } from '../Metadata'
import Page from '../Page'

const Portals = props => (
  <Page hideFooter>
    <Metadata title={props.title} />
    <PortalNav pages={props.portals} content={props.content} />
  </Page>
)

export default Portals
