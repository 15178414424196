import { useTranslation } from 'react-i18next'
import Image from 'next/image'
import styled, { css } from 'styled-components'

import { modularScale } from 'bl-common/src/constants/sizes'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'

const theBluelagoonSocialMedia = [
  {
    icon: '/facebook.svg',
    link: 'https://www.facebook.com/BlueLagoonIceland',
    alt: 'Facebook',
  },
  {
    icon: '/instagram.svg',
    link: 'https://instagram.com/bluelagoonis',
    alt: 'Instagram',
  },
  { icon: '/x.svg', link: 'https://twitter.com/BlueLagoonIS', alt: 'Twitter' },
  {
    icon: '/linkedin.svg',
    link: 'https://www.linkedin.com/company/blue-lagoon-iceland',
    alt: 'LinkedIn',
  },
  {
    icon: '/tiktok.svg',
    link: 'https://www.tiktok.com/@bluelagooniceland',
    alt: 'TikTok',
  },
]

const theRetreatSocialMedia = [
  {
    icon: '/facebook.svg',
    link: 'https://www.facebook.com/Retreat.BlueLagoon',
    alt: 'Facebook',
  },
  {
    icon: '/instagram.svg',
    link: 'https://www.instagram.com/retreat.bluelagoon/',
    alt: 'Instagram',
  },
]

const Container = styled.div`
  padding-top: ${between(41 / modularScale, 41)};
  ${media.md(css`
    padding: ${between(41 / modularScale, 41)} ${between(41 / modularScale, 41)}
      0;
  `)};
`

const DomainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: ${({ theme }) => theme.spacing[1]};
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`

const Logos = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
`

const SocialImage = styled(Image)`
  filter: invert(100%);
`

const FollowUs = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Type preset="headlineMedium" bottom={{ xs: 1 }}>
        {t('followUs')}
      </Type>
      <DomainContainer>
        <Type preset="textLarge" weight="bold">
          The Blue Lagoon
        </Type>
        <Logos>
          {theBluelagoonSocialMedia.map((socialMedia, index) => (
            <Link key={index} to={socialMedia.link}>
              <SocialImage
                src={socialMedia.icon}
                alt={socialMedia.alt}
                width={24}
                height={24}
                layout="fixed"
                color="#fff"
              />
            </Link>
          ))}
        </Logos>
      </DomainContainer>
      <DomainContainer>
        <Type preset="textLarge" weight="bold">
          The Retreat
        </Type>
        <Logos>
          {theRetreatSocialMedia.map((socialMedia, index) => (
            <Link key={index} to={socialMedia.link}>
              <SocialImage
                src={socialMedia.icon}
                alt={socialMedia.alt}
                width={24}
                height={24}
                layout="fixed"
              />
            </Link>
          ))}
        </Logos>
      </DomainContainer>
    </Container>
  )
}

export default FollowUs
