import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { IPageBirthday } from 'bl-common/src/generated/contentful'
import { Section } from 'bl-common/src/units/Section/Section'

import { GradientSwitcher } from '../../../GradientSwitcher'
import { Metadata } from '../../../Metadata'
import Page from '../../../Page'
import SectionsRenderer from '../../SectionsRenderer'
import { ContentTemplateProps } from '../types'
import BirthdayLogo from './logo.svg'
import BirthdayLogoEn from './logo-en.svg'

const BirthdayLogoImg = styled.img({
  width: 100,

  [`@media (min-width: ${breakpoints.md}px)`]: {
    width: 160,
  },
})

const BirthdayPage = ({
  content: {
    fields: { title, sections, customFooter },
  },
  metadata,
  config,
}: ContentTemplateProps<IPageBirthday>) => {
  const props = {
    breadcrumb: title,
    config,
    footer: customFooter,
  }

  const { i18n } = useTranslation()

  // NOTE: data-navigation is necessary in order for GradientSwitcher to get the [data-navigation] element for the event observer.
  return (
    <Page config={config} {...props}>
      <GradientSwitcher>
        <Section top={{ xs: 1, md: 2 }} bottom={0} data-navigation>
          <BirthdayLogoImg
            src={i18n?.language === 'en' ? BirthdayLogoEn : BirthdayLogo}
          />
        </Section>

        <SectionsRenderer sections={sections} pageConfig={config} />
        <Metadata metadata={metadata} title={title} />
      </GradientSwitcher>
    </Page>
  )
}

export default BirthdayPage
