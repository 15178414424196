import { Markdown } from 'bl-common/src/elements/Markdown/Markdown'
import { ISplitPage } from 'bl-common/src/generated/contentful'
import { SmartImage } from 'bl-common/src/units/SmartImage/SmartImage'

import { Metadata } from '../../../Metadata'
import Page from '../../../Page'
import SectionsRenderer from '../../SectionsRenderer'
import { ContentTemplateProps } from '../types'
import FollowUs from './FollowUs'
import * as styles from './styles'
import SupportCenter from './SupportCenter'

const Split = ({
  content: {
    fields: {
      title,
      sidebar,
      sidebarImage,
      sections,
      textColor = 'light',
      gradient,
      footerSections,
    },
  },
  metadata,
  config,
}: ContentTemplateProps<ISplitPage>) => {
  return (
    <Page>
      <Metadata title={title} metadata={metadata} />
      <styles.Outer>
        <styles.Container>
          <styles.Content>
            <SectionsRenderer sections={sections} pageConfig={config} />
          </styles.Content>
          {sidebarImage && (
            <SmartImage image={sidebarImage}>
              {imageUrl => <styles.Sidebar image={imageUrl} />}
            </SmartImage>
          )}
          {!sidebarImage && sidebar && (
            <styles.Sidebar gradient={gradient} textColor={textColor}>
              <Markdown>{sidebar}</Markdown>
            </styles.Sidebar>
          )}
          {!sidebarImage && !sidebar && (
            <styles.Sidebar
              isFlex
              gradient={
                gradient || {
                  fields: {
                    color1: '#52A5B8',
                    color2: '#6793AE',
                    degrees: '91.17deg',
                  },
                }
              }
              textColor={textColor || 'light'}
            >
              <styles.SidebarInner>
                <SupportCenter />
                <FollowUs />
              </styles.SidebarInner>
            </styles.Sidebar>
          )}
        </styles.Container>
      </styles.Outer>
      <SectionsRenderer sections={footerSections} pageConfig={config} />
    </Page>
  )
}

export default Split
