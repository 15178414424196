import { useIntl } from 'react-intl'
import { globalMessages } from '@messages/globalMessages'
import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Logo as BLLogo } from 'bl-common/src/elements/Icons/Logo'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import { ClientOnlyModal } from 'bl-common/src/units/ClientOnlyModal'
import { ModalSize } from 'bl-common/src/units/Modal'
import { mediaObj } from 'bl-common/src/utils/media'

type SelectLanguageModalProps = {
  show: boolean
  onHide: () => void
}

const ModalContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  margin: '0 auto',
  padding: `${theme.spacing[4]} ${theme.spacing[3]}`,
  background: colors.backgroundWhite,
  minWidth: '30vw',
  maxWidth: '600px',
  textWrap: 'pretty',
})

const Logo = styled(BLLogo)({
  width: '100%',
  maxWidth: '130px',
  marginBottom: theme.spacing[4],

  [mediaObj.md]: {
    marginBottom: theme.spacing[2.5],
  },
})

const ModalButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing[2],
  width: '100%',

  [mediaObj.md]: {
    flexDirection: 'row',
  },
})

export const SelectLanguageModal = ({
  show,
  onHide,
}: SelectLanguageModalProps) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { formatMessage } = useIntl()

  return (
    <ClientOnlyModal
      show={show}
      onHide={onHide}
      size={ModalSize.small}
      fullScreenOnMobile={false}
      isAboveEverything
    >
      <ModalContainer>
        <Logo />
        <Type as="h2" preset="headline" bottom={{ xs: 2 }} underline>
          {formatMessage(globalMessages.selectLanguageModalTitle)}
        </Type>
        <Type preset="textLarge" bottom={{ xs: 3 }} size={{ xs: 16, md: 20 }}>
          {formatMessage(globalMessages.selectLanguageModalSubtitle)}
        </Type>
        <ModalButtonWrapper>
          <Button to="/" paddingSize="large" onClick={onHide}>
            English
          </Button>
          <Button paddingSize="large" onClick={onHide}>
            Icelandic
          </Button>
        </ModalButtonWrapper>
      </ModalContainer>
    </ClientOnlyModal>
  )
}
