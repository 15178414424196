/* eslint-disable no-nested-ternary */

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ITopic } from 'bl-common/src/generated/contentful'
import { Appear } from 'bl-common/src/units/Appear'
import { CardCarousel } from 'bl-common/src/units/CardCarousel/CardCarousel'
import { Section } from 'bl-common/src/units/Section/Section'

import { formatCoverCard } from '../../../../utils/formatters'
import { BreadcrumbFab, BreadcrumbLink } from '../../../BreadcrumbLink'
import { GradientSwitcher } from '../../../GradientSwitcher'
import { Metadata } from '../../../Metadata'
import Page from '../../../Page'
import TopicContainer from '../../../TopicContainer'
import SectionsRenderer from '../../SectionsRenderer'
import { ContentTemplateProps } from '../types'
import { TopicHero } from './TopicHero'

const Topic = ({
  metadata,
  config,
  content: {
    fields: {
      backgroundContrast,
      backgroundImage,
      mobileBackgroundImage,
      topicSections,
      topicSubtitle,
      topicTitle,
      relatedTopicsHeading,
      relatedTopicsSubheading,
      relatedTopics,
    },
  },
}: ContentTemplateProps<ITopic>) => {
  const formattedCards =
    relatedTopics && relatedTopics.map(card => formatCoverCard(card))

  return (
    <Page hideFooter isFramed scrollHeader config={config}>
      <Metadata
        metadata={metadata}
        title={topicTitle}
        description={topicSubtitle}
        image={backgroundImage}
      />
      <TopicContainer>
        <BreadcrumbFab />
        <TopicHero
          image={backgroundImage}
          mobileImage={mobileBackgroundImage}
          backgroundContrast={backgroundContrast}
        >
          <BreadcrumbLink />
          <Appear>
            <Type
              as="h1"
              preset="headlineLarge"
              top={{ xs: 1, md: 1 }}
              bottom={{ xs: 0.5, md: 1 }}
            >
              {topicTitle}
            </Type>
          </Appear>
          <Appear>
            <Type preset="subtitle" spacing={1}>
              {topicSubtitle}
            </Type>
          </Appear>
        </TopicHero>
        <GradientSwitcher>
          <SectionsRenderer sections={topicSections} pageConfig={config} />
          {relatedTopics && (
            <Section
              top={{ xs: 4, md: 6 }}
              bottom={{ xs: 4, md: 6 }}
              textColor="dark"
            >
              {relatedTopicsHeading && (
                <Type
                  preset="headlineSmall"
                  bottom={{
                    xs: relatedTopicsSubheading ? 0.5 : 4,
                    md: relatedTopicsSubheading ? 0.5 : 2,
                  }}
                >
                  {relatedTopicsHeading}
                </Type>
              )}
              {relatedTopicsSubheading && (
                <Type preset="subtitle" bottom={{ xs: 3, md: 3 }} multiline>
                  {relatedTopicsSubheading}
                </Type>
              )}
              <CardCarousel
                cards={formattedCards}
                id="Related topics"
                minItemWidthFrom={220}
                minItemWidthTo={273}
              />
            </Section>
          )}
        </GradientSwitcher>
      </TopicContainer>
    </Page>
  )
}

export default Topic
