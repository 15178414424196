import { Fragment } from 'react'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { Type } from '../../elements/Typography/Typography'
import { LightBoxItem } from '../../units/LightBox/LightBoxItem'
import { media } from '../../utils/media'
import GalleryImage from './GalleryImage'

type ItemContainerProps = {
  side?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const TilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.md(css`
    flex-wrap: nowrap;
  `)};
`

const GalleryItemSpacing = styled.div`
  padding-bottom: 16px;
  ${media.md(css`
    padding-bottom: ${({ theme }) => theme.spacing[4.5]};
  `)};
`

const ItemContainer = styled.div<ItemContainerProps>`
  flex: 1 1 auto;

  ${props => {
    if (props.side && props.side === 'left') {
      return css`
        padding-right: 16px;
        ${media.md(css`
          padding-right: ${({ theme }) => theme.spacing[2.5]};
        `)};
      `
    }
  }};
`

const HeroContainer = styled.div`
  flex: none;
`

const components = {
  suiteGalleryImage: GalleryImage,
}

const groupItemsByType = items => {
  if (!items.length) {
    return []
  }

  const arr = []
  let lastArr = null
  let lastGroup = null

  items.forEach(item => {
    const itemGroup = item.fields.size === 'Full Width' ? item.sys.id : 'tile'
    if (itemGroup !== lastGroup) {
      lastArr = []
      lastGroup = itemGroup
      arr.push({
        type: itemGroup === 'tile' ? 'tile' : 'hero',
        items: lastArr,
      })
    }
    lastArr.push(item)
  })

  return arr
}

const TileItems = ({ items }) => {
  if (!items) return null

  const sliceAmt = Math.ceil(items.length / 2)
  const arr1 = items.slice(0, sliceAmt)
  const arr2 = items.slice(sliceAmt)

  return (
    <TilesContainer>
      <ItemContainer side="left">
        {arr1.map(item => {
          const type = get(item, 'sys.contentType.sys.id')
          const key = get(item, 'sys.id')
          if (!components[type]) return null
          const Component = components[type]
          return (
            <GalleryItemSpacing key={key}>
              <LightBoxItem image={get(item, 'fields.image')}>
                <Component item={item.fields} />
              </LightBoxItem>
            </GalleryItemSpacing>
          )
        })}
      </ItemContainer>
      <ItemContainer side="right">
        {arr2.map(item => {
          const type = get(item, 'sys.contentType.sys.id')
          const key = get(item, 'sys.id')
          if (!components[type]) return null
          const Component = components[type]
          return (
            <GalleryItemSpacing key={key}>
              <LightBoxItem image={get(item, 'fields.image')}>
                <Component item={item.fields} />
              </LightBoxItem>
            </GalleryItemSpacing>
          )
        })}
      </ItemContainer>
    </TilesContainer>
  )
}

const HeroItem = ({ items: [firstItem] }) => {
  return (
    <GalleryItemSpacing>
      <LightBoxItem image={get(firstItem, 'fields.image')}>
        <HeroContainer>
          <GalleryImage item={firstItem.fields} />
        </HeroContainer>
      </LightBoxItem>
    </GalleryItemSpacing>
  )
}

export const RoomGallery = ({ items, heading, multilineSubheading }) => {
  const groupedItems = groupItemsByType(items)

  return (
    <Fragment>
      {heading && (
        <Type
          as="h2"
          preset="headline"
          bottom={{
            xs: multilineSubheading ? 0.5 : 2,
            md: multilineSubheading ? 0.5 : 4,
          }}
        >
          {heading}
        </Type>
      )}
      {multilineSubheading && (
        <Type preset="subtitle" bottom={{ xs: 2, md: 4 }} multiline>
          {multilineSubheading}
        </Type>
      )}
      <Container>
        {groupedItems.map((itemGroup, index) => {
          if (itemGroup.type === 'tile') {
            return (
              <TileItems key={`tileItem${index}`} items={itemGroup.items} />
            )
          }

          if (itemGroup.type === 'hero') {
            return <HeroItem key={`tileItem${index}`} items={itemGroup.items} />
          }
          return null
        })}
      </Container>
    </Fragment>
  )
}
