import styled, { css } from 'styled-components'

import { durations } from 'bl-common/src/constants/durations'
import { modularScale } from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { between } from 'bl-common/src/utils/between'
import { media, mediaMax } from 'bl-common/src/utils/media'

export const LocationBoxContainer = styled.div`
  display: flex;

  ${mediaMax.md(css`
    flex-direction: column;
    width: 100%;
  `)};
`

const Container = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  padding: 16px;
  z-index: ${zIndex.above};

  ${mediaMax.md(`
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  `)};

  ${media.md(css`
    padding: ${between(24 / modularScale, 24)};
    flex: 1;
    & + & {
      margin-left: 42px;
    }
  `)};
  &::after {
    background: linear-gradient(137deg, #78b2be, #c0c1b5);
    bottom: 0;
    content: '';
    cursor: pointer;
    left: 0;
    opacity: 0.15;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(1);
    transition: transform ${durations.short}ms;
    z-index: ${zIndex.behind};
  }

  ${media.md(css`
    &:hover {
      &::after {
        transform: scale(1.05);
      }
    }
  `)};
`

const Icon = styled.div<{ icon: string }>`
  background-repeat: no-repeat;
  background-size: cover;
  height: 42px;
  margin-bottom: ${between(42 / modularScale, 42)};
  margin-top: ${between(16 / modularScale, 16)};
  width: 42px;
  ${({ icon }) => css`
    background-image: url(${icon});
  `};

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const LocationBox = ({ icon, text, link }) => (
  <Container to={link}>
    <Icon icon={icon && icon.fields.file.url} />
    <Type weight="bold" size={{ md: 14 }}>
      {text}
    </Type>
  </Container>
)

export default LocationBox
