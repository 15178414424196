import { Asset } from 'contentful'
import { CarouselProvider } from 'pure-react-carousel'

import { Bars, CarouselSlider } from '../../elements/Carousel/Carousel'
import { ContentfulImage } from '../../elements/ContentfulImage'
import { Type } from '../../elements/Typography/Typography'
import { useBreakpoints } from '../../hooks/useBreakpoints'
import * as styles from './styles'

type HotelRoomHeroProps = {
  title: string
  subtitle?: string
  images: Asset[]
}

export const HotelRoomHero = ({
  title,
  images = [],
  subtitle,
}: HotelRoomHeroProps) => {
  const { mediaMd } = useBreakpoints()
  return (
    <styles.HotelRoomHero>
      <styles.HeroCarousel>
        <CarouselProvider
          naturalSlideHeight={mediaMd ? 400 : 460}
          naturalSlideWidth={350}
          totalSlides={images.length}
        >
          <CarouselSlider images={images} />
          <Bars />
        </CarouselProvider>
      </styles.HeroCarousel>
      <styles.HeroImage>
        <ContentfulImage image={images[0]} />
      </styles.HeroImage>
      <styles.HeroContent>
        <Type
          size={{ xs: 32, md: 48 }}
          weight="bold"
          bottom={{ xs: 0.5, md: 1 }}
        >
          {title}
        </Type>
        {subtitle && (
          <Type preset="textLarge" weight="bold" opacity={0.75}>
            {subtitle}
          </Type>
        )}
      </styles.HeroContent>
    </styles.HotelRoomHero>
  )
}
